





















import {RowInterface} from 'App/interfaces';
import {RowComponent} from 'App/layout/body';
import {
    default as Vue,
    PropType,
}                     from 'vue';
import {
    default as Component,
    mixins,
}                     from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            RowComponent,
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
            rows    : {
                required: true,
                type    : Array as PropType<Array<RowInterface>>,
            },
        },
    },
);

@Component
export default class MainComponent
    extends mixins(definition)
{
}
