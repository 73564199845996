




















import {DataInterface} from 'App/layout/header/form';
import {
    default as Vue,
    PropType,
}                      from 'vue';
import {
    default as Component,
    mixins,
}                      from 'vue-class-component';

const definition = Vue.extend(
    {
        data(): DataInterface
        {
            return {
                value: this.prefix,
            };
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
            prefix  : {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class FormComponent
    extends mixins(definition)
{
    public onChange(): void
    {
        this.eventBus.$emit(
            'prefix',
            this.value,
        );
    }
}
