import {
    RedirectGroupInterface,
    RowInterface,
}                           from 'App/interfaces';
import {redirectFlags}      from 'App/util';
import {escapeStringRegexp} from 'App/util/helpers/regexes';
import {vsprintf}           from 'sprintf-js';

/**
 *
 * @param {RowInterface} row
 * @returns {string}
 */
const buildRedirectLine = (
    row: RowInterface,
): string => {
    const urlIn = row.in.url;
    const urlOut = row.out.url;
    if (
        urlIn === null
        ||
        urlOut === null
    ) {
        throw new Error(
            'Invalid row',
        );
    }

    const pathName = urlIn.pathname.substr(1);
    const path = escapeStringRegexp(pathName);

    return vsprintf(
        'RewriteRule ^%s$ %s [%s]',
        [
            path,
            urlOut.toString(),
            redirectFlags,
        ],
    );
};

/**
 *
 * @param {RedirectGroupInterface} redirectGroup
 * @returns {string}
 */
export const buildRedirectBlock = (
    redirectGroup: RedirectGroupInterface,
): string => {
    const hostname = escapeStringRegexp(redirectGroup.hostname);

    const redirectLines: string = redirectGroup
        .urls
        .map(buildRedirectLine)
        .join('\n');

    // NOTE: %% is needed by sprintf-js to display %
    return vsprintf(
        `RewriteCond %%{HTTP_HOST} !%s$
RewriteRule .* - [S=%s]
%s`,
        [
            hostname,
            redirectGroup.urls.length,
            redirectLines,
        ],
    );
};
