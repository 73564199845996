








































import {IconsInterface} from 'App/dependencies/vue/plugins/fontawesome';
import {
    default as Vue,
    PropType,
}                       from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
        },
    },
);

@Component
export default class ResetComponent
    extends mixins(definition)
{
    public get icons(): IconsInterface
    {
        return {
            trash: [
                'fas',
                'trash',
            ],
        };
    }

    public onOk(): void
    {
        this.eventBus.$emit('reset');
    }
}
