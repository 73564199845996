












































import {default as CONFIG} from 'App/config.json';
import {
    FormComponent,
    ToolbarComponent,
}                          from 'App/layout/header';
import {
    default as Vue,
    PropType,
}                          from 'vue';
import {
    default as Component,
    mixins,
}                          from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            FormComponent,
            ToolbarComponent,
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
            prefix  : {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class HeaderComponent
    extends mixins(definition)
{
    public get appTitle(): string
    {
        const appName: string = CONFIG['app.name'];
        const appVersion: string = CONFIG['app.version'];

        const appTitle: string = `${appName} v${appVersion}`;

        window.document.title = appTitle;

        return appTitle;
    }
}
