import {default as baseEscapeStringRegexp} from 'escape-string-regexp';

/**
 *
 * @param {string} value
 * @returns {string}
 */
export const escapeStringRegexp = (
    value: string,
): string => {
    return baseEscapeStringRegexp(value)
        .replace(
            /\\x2d/g,
            '-',
        );
};
