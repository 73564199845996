
























import {RowInterface}   from 'App/interfaces';
import {
    DataInterface,
    FooterComponent,
    HeaderComponent,
    MainComponent,
    ModalsComponent,
}                       from 'App/layout';
import {
    buildRow,
    findIndex,
}                       from 'App/util/helpers/rows';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            FooterComponent,
            HeaderComponent,
            MainComponent,
            ModalsComponent,
        },
        data(): DataInterface
        {
            const prefix: string = localStorage.getItem('prefix') ?? '';
            const rowsJSON: string = localStorage.getItem('rows') ?? '[]';

            return {
                eventBus: new Vue(),
                prefix,
                rows    : JSON.parse(rowsJSON),
            };
        },
    },
);

@Component
export default class AppLayout
    extends mixins(definition)
{
    public created(): void
    {
        this.eventBus.$on(
            'import',
            (
                rows: RowInterface[],
                reset: boolean,
            ): void => {
                if (reset) {
                    this.rows = [];
                }

                this.rows.push(...rows);

                this.updateRows();
            },
        );
        this.eventBus.$on(
            'prefix',
            (
                prefix: string,
            ): void => {
                const replaceValue = (
                    input: string,
                ): string => {
                    let value: string = input;
                    if (value.startsWith(this.prefix)) {
                        value = value.replace(
                            this.prefix,
                            '',
                        );
                    }
                    if (value.startsWith(prefix)) {
                        value = value.replace(
                            prefix,
                            '',
                        );
                    }

                    return prefix + value;
                };

                this.rows = this
                    .rows
                    .map(
                        (
                            row: RowInterface,
                        ): RowInterface => {
                            row.in.value = replaceValue(row.in.value);

                            return row;
                        },
                    );

                this.updatePrefix(prefix);
                this.updateRows();
            },
        );
        this.eventBus.$on(
            'reset',
            (): void => {
                localStorage.clear();

                this.updatePrefix('');

                this.eventBus.$emit(
                    'import',
                    [],
                    true,
                );
            },
        );
        this.eventBus.$on(
            'row-delete',
            (
                hash: string,
            ): void => {
                const index = this.findRowIndex(hash);

                this.rows.splice(
                    index,
                    1,
                );

                this.updateRows();
            },
        );
        this.eventBus.$on(
            'row-update',
            (
                row: RowInterface,
            ): void => {
                const index = this.findRowIndex(row.hash);

                const element: RowInterface = this.rows[index];

                element.in.value = row.in.value;
                element.out.value = row.out.value;

                this.updateRows();
            },
        );
    }

    public mounted(): void
    {
        this.updateRows();
    }

    private findRowIndex(
        hash: string,
    ): number
    {
        const index = findIndex(
            hash,
            this.rows,
        );

        if (index === -1) {
            throw new Error(
                `Hash not found: '${hash}'`,
            );
        }

        return index;
    }

    private updatePrefix(
        value: string,
    ): void
    {
        this.prefix = value;

        localStorage.setItem(
            'prefix',
            this.prefix,
        );
    }

    private updateRows(): void
    {
        this.rows = this
            .rows
            .filter(
                (
                    row: RowInterface,
                ): boolean => {
                    return row.in.value !== ''
                           ||
                           row.out.value !== '';
                },
            )
            .map(
                (
                    row: RowInterface,
                ): RowInterface => {
                    return buildRow(
                        row.in.value,
                        row.out.value,
                    );
                },
            )
            .reduce(
                (
                    carry: Array<RowInterface>,
                    row: RowInterface,
                ): Array<RowInterface> => {
                    const index = findIndex(
                        row.hash,
                        carry,
                    );

                    if (index === -1) {
                        carry.push(row);
                    }

                    return carry;
                },
                [],
            );

        const rowsJson = JSON.stringify(this.rows);
        localStorage.setItem(
            'rows',
            rowsJson,
        );

        this.rows.push(
            buildRow(
                '',
                '',
            ),
        );
    }
}
