




















import {RowInterface} from 'App/interfaces';
import {
    ExportComponent,
    ImportComponent,
    ResetComponent,
}                     from 'App/layout/modals';
import {
    default as Vue,
    PropType,
}                     from 'vue';
import {
    default as Component,
    mixins,
}                     from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            ExportComponent,
            ImportComponent,
            ResetComponent,
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
            rows    : {
                required: true,
                type    : Array as PropType<Array<RowInterface>>,
            },
        },
    },
);

@Component
export default class ModalsComponent
    extends mixins(definition)
{
    public get validRows(): RowInterface[]
    {
        return this
            .rows
            .filter(
                (
                    row: RowInterface,
                ): boolean => {
                    return row.in.url !== null
                           &&
                           row.out.url !== null;
                },
            );
    }
}
