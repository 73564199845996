import {RowInterface} from 'App/interfaces';
import {
    hashUrl,
    parseUrl,
}                     from 'App/util/helpers/urls';

/**
 *
 * @param {string} hash
 * @param {Array<RowInterface>} rows
 * @returns {number}
 */
export const findIndex = (
    hash: string,
    rows: Array<RowInterface>,
): number => {
    return rows.findIndex(
        (
            row: RowInterface,
        ): boolean => {
            return row.hash === hash;
        },
    );
};

/**
 *
 * @param {string} valueIn
 * @param {string} valueOut
 * @returns {RowInterface}
 */
export const buildRow = (
    valueIn: string,
    valueOut: string,
): RowInterface => {
    return {
        hash: hashUrl(
            valueIn,
            valueOut,
        ),
        in  : parseUrl(valueIn),
        out : parseUrl(valueOut),
    };
};
