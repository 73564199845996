















import {StyleInterface} from 'App/dependencies/vue';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            size: {
                default: 3,
                type   : Number,
            },
            type: {
                default: 'border',
                type   : String,
            },
        },
    },
);

@Component
export default class SpinnerComponent
    extends mixins(definition)
{
    public get style(): StyleInterface
    {
        const size = `${this.size}rem`;

        return {
            'height': size,
            'width' : size,
        };
    }
}
