
















import {IconsInterface} from 'App/dependencies/vue/plugins/fontawesome';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {},
);

@Component
export default class ResetComponent
    extends mixins(definition)
{
    public get icons(): IconsInterface
    {
        return {
            trash: [
                'fas',
                'trash',
            ],
        };
    }
}
