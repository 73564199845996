













































































































































import {SpinnerComponent} from 'App/components';
import {IconsInterface}   from 'App/dependencies/vue/plugins/fontawesome';
import {
    convertToBlob,
    DataInterface,
    FormInterface,
    importRows,
}                         from 'App/layout/modals/import';
import {
    default as Vue,
    PropType,
}                         from 'vue';
import {
    default as Component,
    mixins,
}                         from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            SpinnerComponent,
        },
        data(): DataInterface
        {
            const form: FormInterface = {
                file  : null,
                keep  : true,
                prefix: '',
                text  : '',
                type  : 'text',
            };

            return {
                initialForm: form,
                form,
                options    : [
                    {
                        text : 'texte',
                        value: 'text',
                    },
                    {
                        text : 'fichier',
                        value: 'file',
                    },
                ],
                processing : false,
            };
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
        },
    },
);

@Component
export default class ImportComponent
    extends mixins(definition)
{
    public get icons(): IconsInterface
    {
        return {
            fileImport: [
                'fas',
                'file-import',
            ],
            undoAlt   : [
                'fas',
                'undo-alt',
            ],
        };
    }

    public onReset(): void
    {
        Object.assign(
            this.form,
            this.initialForm,
        );
    }

    public onSubmit(): void
    {
        this.processing = true;

        const blobPart: BlobPart | null = this.form.type === 'file'
            ? this.form.file
            : this.form.text;

        const promise = blobPart === null
            ? Promise.reject('no file selected')
            : convertToBlob(blobPart)
                .text();

        promise
            .then(
                (
                    text: string,
                ): void => {
                    const rows = importRows(
                        text,
                        this.form.prefix,
                    );

                    this.eventBus.$emit(
                        'import',
                        rows,
                        !this.form.keep,
                    );

                    this.$bvModal.hide('import-modal');
                },
            )
            .catch(
                (
                    error: unknown,
                ): void => {
                    window.console.error(error);
                },
            )
            .finally(
                (): void => {
                    this.processing = false;
                },
            );
    }
}
