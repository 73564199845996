import {library}         from '@fortawesome/fontawesome-svg-core';
import {
    faCheck,
    faCopy,
    faEye,
    faFileExport,
    faFileImport,
    faMinus,
    faTrash,
    faUndoAlt,
}                        from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {default as Vue}  from 'vue';

library.add(
    faCheck,
    faCopy,
    faEye,
    faFileExport,
    faFileImport,
    faMinus,
    faTrash,
    faUndoAlt,
);

Vue.component(
    'font-awesome-icon',
    FontAwesomeIcon,
);
