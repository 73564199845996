












import {MessageInterface} from 'App/components/message';
import {
    default as Vue,
    PropType,
}                         from 'vue';
import {
    default as Component,
    mixins,
}                         from 'vue-class-component';

const definition = Vue.extend(
    {
        props     : {
            message: {
                required: true,
                type    : Object as PropType<MessageInterface>,
            },
        },
    },
);

@Component
export default class MessageComponent
    extends mixins(definition)
{
}
