




























































import {SpinnerComponent}   from 'App/components';
import {IconsInterface}     from 'App/dependencies/vue/plugins/fontawesome';
import {
    RedirectGroupInterface,
    RowInterface,
}                           from 'App/interfaces';
import {DataInterface}      from 'App/layout/modals/export';
import {buildRedirectBlock} from 'App/util/helpers/htaccess';
import {
    default as Vue,
    PropType,
}                           from 'vue';
import {
    default as Component,
    mixins,
}                           from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            SpinnerComponent,
        },
        data(): DataInterface
        {
            return {
                processing: false,
                result    : '',
            };
        },
        props     : {
            rows: {
                required: true,
                type    : Array as PropType<Array<RowInterface>>,
            },
        },
    },
);

@Component
export default class ExportComponent
    extends mixins(
        definition,
    )
{
    public get icons(): IconsInterface
    {
        return {
            copy      : [
                'fas',
                'copy',
            ],
            fileExport: [
                'fas',
                'file-export',
            ],
        };
    }

    public copy(): void
    {
        window
            .navigator
            .clipboard
            .writeText(this.result)
            .then(
                (): void => {
                    window.console.info('copied');
                },
            )
            .catch(
                (
                    error: unknown,
                ): void => {
                    window.console.error(error);
                },
            );
    }

    public mounted(): void
    {
        this
            .$root
            .$on(
                'bv::modal::show',
                (): void => {
                    this.processing = false;

                    this.result = this
                        .rows
                        .reduce(
                            (
                                carry: RedirectGroupInterface[],
                                row: RowInterface,
                            ): RedirectGroupInterface[] => {
                                const urlIn = row.in.url;
                                if (urlIn === null) {
                                    throw new Error(
                                        'Invalid row',
                                    );
                                }

                                const defaultRedirectGroup: RedirectGroupInterface = {
                                    urls    : [],
                                    hostname: urlIn.hostname,
                                };

                                let redirectGroup = carry.find(
                                    (
                                        redirectGroup: RedirectGroupInterface,
                                    ): boolean => {
                                        return redirectGroup.hostname === urlIn.hostname;
                                    },
                                );
                                if (redirectGroup === undefined) {
                                    redirectGroup = defaultRedirectGroup;
                                    carry.push(redirectGroup);
                                }

                                redirectGroup.urls.push(row);

                                return carry;
                            },
                            [],
                        )
                        .map(buildRedirectBlock)
                        .join('\n\n');

                    this.processing = false;
                },
            );
    }

    public onCopySuccess(): void
    {
        this.$bvToast.toast(
            `Succès`,
            {
                autoHideDelay: 1666,
                title        : 'Copie export',
                variant      : 'primary',
            },
        );
    }

    public onCopyFailure(): void
    {
        this.$bvToast.toast(
            `Echec`,
            {
                autoHideDelay: 4200,
                title        : 'Copie export',
                variant      : 'danger',
            },
        );
    }
}
