import {default as AppLayout} from 'App/app.layout.vue';
import 'App/dependencies';
import 'App/styles/app.entry';
import {
    CreateElement,
    default as Vue,
    VNode,
}                             from 'vue';

window.addEventListener(
    'beforeunload',
    (
        beforeUnloadEvent: BeforeUnloadEvent,
    ): void => {
        beforeUnloadEvent.preventDefault();

        beforeUnloadEvent.returnValue = '';
    },
);

const id: string = 'vue-app';
const element: HTMLElement | null = window.document.getElementById(id);
if (element === null) {
    throw new Error(
        `Element '${id}' does not exist`,
    );
}

new Vue(
    {
        components: {
            AppLayout,
        },
        el        : element,
        render    : (
            createElement: CreateElement,
        ): VNode => {
            return createElement(AppLayout);
        },
        template  : '<AppLayout />',
    },
);
