


















import {
    ExportComponent,
    ImportComponent,
    ResetComponent,
}                       from 'App/layout/header/toolbar';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            ExportComponent,
            ImportComponent,
            ResetComponent,
        },
    },
);

@Component
export default class ToolbarComponent
    extends mixins(definition)
{
}
