import {UrlInterface}    from 'App/interfaces';
import {default as hash} from 'hash.js';

/**
 *
 * @param {string} value
 * @returns {UrlInterface}
 */
export const parseUrl = (
    value: string,
): UrlInterface => {
    let url: URL | null;

    try {
        url = new URL(value);
    } catch (error) {
        url = null;
    }

    return {
        url,
        value,
    };
};

/**
 *
 * @param {string} valueIn
 * @param {string} valueOut
 * @returns {string}
 */
export const hashUrl = (
    valueIn: string,
    valueOut: string,
): string => {
    const value: string = `${valueIn}|${valueOut}`;

    return hash
        .sha256()
        .update(value)
        .digest('hex');
};

/**
 *
 * @param {URL | null} url
 * @returns {Array<string>}
 */
export const analyzeUrl = (
    url: URL | null,
): Array<string> => {
    const protocols: Array<string> = [
        'http:',
        'https:',
    ];

    const errors: Array<string> = [];

    if (url === null) {
        errors.push('url_invalid');
    } else {
        if (url.hash !== '') {
            errors.push('hash_set');
        }
        if (url.password !== '') {
            errors.push('password_set');
        }
        if (url.port !== '') {
            errors.push('port_set');
        }
        if (!protocols.includes(url.protocol)) {
            errors.push('protocol_invalid');
        }
        if (url.search !== '') {
            errors.push('search_set');
        }
        if (url.username !== '') {
            errors.push('username_set');
        }
    }

    return errors;
};
