import {UrlRowInterface} from 'App/interfaces';

/**
 *
 * @param {string} rows
 * @param {string} prefix
 * @returns {Array<UrlRowInterface>}
 */
export const importRows = (
    rows: string,
    prefix: string,
): Array<UrlRowInterface> => {
    return rows
        .split('\n')
        .map(
            (
                row: string,
            ): UrlRowInterface => {
                const values: Array<string> = `${row};`
                    .replace(
                        ',',
                        ';',
                    )
                    .split(';')
                    .slice(
                        0,
                        2,
                    );

                return {
                    in : {
                        url  : null,
                        value: `${prefix}${values[0]}`,
                    },
                    out: {
                        url  : null,
                        value: values[1],
                    },
                };
            },
        );
};

/**
 *
 * @param {BlobPart} blob
 * @returns {Blob}
 */
export const convertToBlob = (
    blob: BlobPart,
): Blob => {
    return new Blob(
        [
            blob,
        ],
    );
};
