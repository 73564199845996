




















































































import {MessageComponent} from 'App/components';
import {MessageInterface} from 'App/components/message';
import {RowInterface}     from 'App/interfaces';
import {IconsInterface}   from 'App/dependencies/vue/plugins/fontawesome';
import {ErrorsInterface}  from 'App/layout/body/row';
import {analyzeUrl}       from 'App/util/helpers/urls';
import {
    default as Vue,
    PropType,
}                         from 'vue';
import {
    default as Component,
    mixins,
}                         from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            MessageComponent,
        },
        props     : {
            eventBus: {
                required: true,
                type    : Object as PropType<Vue>,
            },
            row     : {
                required: true,
                type    : Object as PropType<RowInterface>,
            },
        },
    },
);

@Component
export default class RowComponent
    extends mixins(definition)
{
    public get disabledDelete(): boolean
    {
        return this.row.in.value === ''
               &&
               this.row.out.value === '';
    }

    public get disabledLink(): boolean
    {
        return this.row.in.url === null;
    }

    public get errors(): ErrorsInterface
    {
        return this.disabledDelete
            ? {
                in : [],
                out: [],
            }
            : {
                in : analyzeUrl(this.row.in.url),
                out: analyzeUrl(this.row.out.url),
            };
    }

    public get icons(): IconsInterface
    {
        return {
            eye  : [
                'fas',
                'eye',
            ],
            minus: [
                'fas',
                'minus',
            ],
        };
    }

    public created(): void
    {
        if (
            this.row.in.value !== ''
            ||
            this.row.out.value !== ''
        ) {
            this.errors.in = analyzeUrl(this.row.in.url);
            this.errors.out = analyzeUrl(this.row.out.url);
        }
    }

    public error(
        reason: string,
    ): MessageInterface
    {
        let error: MessageInterface;

        switch (reason) {
            case 'hash_set':
                error = {
                    color: 'warning',
                    text : 'Evite de spécifier le fragment',
                };
                break;
            case 'password_set':
                error = {
                    color: 'warning',
                    text : 'Evite de spécifier le mot de passe',
                };
                break;
            case 'port_set':
                error = {
                    color: 'warning',
                    text : 'Evite de spécifier le port',
                };
                break;
            case 'protocol_invalid':
                error = {
                    color: 'warning',
                    text : 'Le protocole est invalide (doit être HTTP ou HTTPS)',
                };
                break;
            case 'search_set':
                error = {
                    color: 'warning',
                    text : 'Evite de spécifier les paramètres',
                };
                break;
            case 'url_invalid':
                error = {
                    color: 'danger',
                    text : 'l\'URL est invalide',
                };
                break;
            case 'username_set':
                error = {
                    color: 'warning',
                    text : 'Evite de spécifier l\'utilisateur',
                };
                break;
            default:
                throw new Error(
                    `Unhandled error '${reason}'`,
                );
        }

        return error;
    }

    public onDelete(): void
    {
        this.eventBus.$emit(
            'row-delete',
            this.row.hash,
        );
    }

    public onChange(): void
    {
        this.eventBus.$emit(
            'row-update',
            this.row,
        );
    }
}
