













































import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend();

@Component
export default class FooterComponent
    extends mixins(definition)
{
}
